import React from "react";

class Error extends React.Component{
    render(){
        return(
            <div style={{paddingTop:'6vh'}}>
                <h1 className={'headline'}>404 -> Page not found</h1>
            </div>
        )
    }
}
export default Error