import React, {Component} from "react";
import '../styles/info.css';
import parse from "html-react-parser";


class Info extends Component{
    state = {
    };
    componentDidMount() {
        const hash = window.location.hash
        const info = document.getElementsByClassName("infoSection")
        this.navigationHighlighter(hash)
        this.navigationOnscrollHighlighterInfo = this.navigationOnscrollHighlighterInfo.bind(this)
        window.addEventListener('scroll', () => this.navigationOnscrollHighlighterInfo(info))
        this.scrollToAnchor(hash)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const hash = window.location.hash;
        this.navigationHighlighter(hash)
        this.scrollToAnchor(hash)

    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.navigationOnscrollHighlighterInfo)
    }


    scrollToAnchor(hash){
        //timeout hack bc off async loading issue
        setTimeout(function(){
            //scroll to anchor if hash and html element true if not scroll top
            if(hash && document.getElementById(hash.replace('#', ''))){
                let pos = document.getElementById(hash.replace('#', '')).offsetTop
                window.scrollTo({
                    top: pos
                })
            }else{
                window.scrollTo(0,0)

            }
        }, 50)

    }

    navigationHighlighter(hash){

        const prev = document.getElementsByClassName('current')
        if(prev[0]){
            prev[0].classList.remove("current")
        }
        const navEl = document.getElementById(hash.replace('#', '') + 'Nav')
        // if hash value then highlight corresponding nav point
        if(hash && navEl){
            navEl.classList.add('current')
        }
        //if no hash then add current to the first nav point by default
        else{
            document.getElementsByClassName('infoNavSpan')[0].classList.add('current')

        }
    }

    // find and highlight the current navigation element and change url
    navigationOnscrollHighlighterInfo(infos){
        let info;

        //set offset for navigation highlighter depending on screen size
        let offset;
        if(window.innerWidth<= 1024){
            offset =  document.getElementById("header").getBoundingClientRect().height;
        }else{
            offset = (window.innerWidth*6)/100;
        }

        for (info of infos){
            //get the matching navigation point to the project
            let currentNav = document.getElementById(info.id + "Nav")
            let elementHeight = info.offsetHeight
            // distance to the top of project in the current looping iteration
            let divDistanceTop = info.getBoundingClientRect().top
            if(divDistanceTop<= offset +1 && divDistanceTop>-(elementHeight-offset) && !currentNav.classList.contains("current")){
                // remove the class from the previous navigation point
                let prev = document.getElementsByClassName("current")
                if(prev[0]){
                    prev[0].classList.remove("current")
                }
                //add class to new nav point
                currentNav.classList.add("current")
                //change url depending on site scroll
                window.history.pushState("","", "/info" + "#" +info.id)
            }
        }
    }

    render() {
        let count = 0;
        let paragraph2 = this.props.info.map(paragraph => {
            count++;
            return(
                <div key={count} id={paragraph.slug} className={"infoSection"}>
                    <h2 className={"headline"}>{paragraph.title.rendered}</h2>
                    <div>{parse(paragraph.content.rendered)}</div>
                </div>
            )

        })

        return (
            <div>
                {paragraph2.reverse()}
            </div>
        );
    }
}
export default Info;