import React, {Component} from 'react';
import Thumbnail from "./Thumbnail";

class Work extends Component{

    componentDidMount() {
        const hash = window.location.hash;
        const scrollToAnchor = this.scrollToAnchor.bind(this, hash)
        setTimeout(function() {
            scrollToAnchor()
        },200)

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const hash = window.location.hash;
        const scrollToAnchor = this.scrollToAnchor.bind(this, hash)
        setTimeout(function() {
            scrollToAnchor()
        },200)
    }

    scrollToAnchor(hash){
        //scroll to anchor if hash and html element true if not scroll top
        if(hash && document.getElementById(hash.replace('#', ''))){
            let pos = document.getElementById(hash.replace('#', '')).offsetTop
            window.scrollTo({
                  top: pos
            })
        }else{
            window.scrollTo(0,0)

        }
    }

    render() {
        //map out all the projects and pass the data
        const projectList = this.props.projects.map(
            (projectList, index) => {
                return(
                    <Thumbnail last={index == this.props.projects.length-1 ? 'last' : `index${index}`} key={projectList.id} info={projectList.post} projects={this.props.projects[index]}/>
                    )
            }
            )
        return(
            <section>
                {projectList}
            </section>
        );

    }
}
export default Work;