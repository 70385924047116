import './App.css';
import React, {Component} from 'react';
import Nav from './components/nav';
import Work from './components/work';
import Info from './components/info';
import WorkDetail from './components/workDetail';
import Error from "./components/404";
import {BrowserRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";

class App extends Component {
    constructor(){
        super();
        this.state = {
            projects: [],
            info: [],
            isLoading: true
        }

    }
    componentDidMount() {
        //get wordpress content
        this.fetchContent()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //call function on screen resize
        window.addEventListener('resize', () => {
            this.headerOffset();
        })
        this.headerOffset()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.headerOffset)
    }

    getProjectData(){
        return fetch("https://backend.noah-mueller.com/wp-json/ext/v1/projects")
            .then(response => response.json())

    }
    getInfoData(){
        return fetch("https://backend.noah-mueller.com/wp-json/wp/v2/pages")
            .then(response => response.json())
    }
    async fetchContent () {
        let projectData = await this.getProjectData()
        let infoData = await this.getInfoData()
        this.setState({
            projects: projectData,
            info: infoData,
            isLoading: false

        })
    }

    headerOffset(){
        let content = document.getElementById("content")
        let nav = document.getElementById("nav")
        if(window.innerWidth<= 1024){
            //set offset for content div
            let header = document.getElementById("header")
            let offset = header.getBoundingClientRect()
            content.style.top = offset.height + 'px'
            nav.style.top = offset.height + 'px'
        }else{
            content.style.top = 0
        }
    }

    toggleMenu(e){
        //disable toggle for desktop
        if(window.screen.width<=1024){
            let nav = document.getElementById('nav')
            let button = document.getElementById('menuBtn')
            if(nav.classList.contains('opened')){
                button.innerHTML = "menu"
                nav.classList.remove('opened')
            }else{
                button.innerHTML = "close"
                nav.classList.add('opened')
            }
        }
    }

    render(){
        return (
            <React.Fragment >
                <Router>
                    <header id={'header'} className='header'>
                        <Link to='/work'>
                            <h1 className="logo">Noah Müller</h1>
                        </Link>
                        <div id={'menuBtn'} onClick={this.toggleMenu} className={"menuBtn"}> menu </div>
                        {this.state.isLoading ? null : <Nav toggleMenu={this.toggleMenu} projects={this.state.projects} />}
                    </header>
                    <div id={"content"} className='content'>
                        <Switch>
                            <Route path="/" exact
                                   render={(props) => <Work {...props} projects={this.state.projects} />}
                            />
                            <Route path="/work" exact
                                   render={(props) => <Work {...props} projects={this.state.projects} />}
                            />
                            <Route path="/info"
                                   render ={(props)=> {
                                       return (
                                           this.state.isLoading ? null : <Info {...props} info={this.state.info}/>
                                       )
                                   }}
                            />
                            <Route path ="/work/:project"
                                   render={(props) => {
                                       return(
                                           //load component when all data is available and pass the projects first image
                                           this.state.isLoading ? null : <WorkDetail {...props} project={this.state.projects} />
                                           )
                                    }
                                   }
                            />
                            <Route path="/404" exact>
                                <Error/>
                            </Route>

                            <Route path="*">
                                <Redirect to={{pathname:'/404'}}/>
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </React.Fragment>
        );
    }

}

export default App;