import React, {Component} from "react";
import './work'
import '../styles/workDetail.css'
import {Link, Redirect} from 'react-router-dom'
import { Helmet } from "react-helmet";


class WorkDetail extends Component{
    constructor(props){
        super(props);
        this.state = {
            //if url param unset then imageNumber = 1
            data:this.getImage(this.getProject(),
                window.location.search.replace("?image=", '') ?
                    window.location.search.replace("?image=", ''):
                    "1"),
            redirect: false,
            isMobile: window.innerWidth < 1024
        }
    }
    componentDidMount() {
        //on mobile redirect to thumbnails page
        if(window.screen.width<1024){
            this.redirect('/work')
        }else if(this.state.data[0] === undefined || this.getProject()==undefined){
            console.log(this.state.data[0])
            this.redirect('/404')
        }else{
            window.scrollTo(0,0)
            // highlight the current navigation point
            const prevNavEl = document.getElementsByClassName("current")
            if(prevNavEl[0]!=null){
                prevNavEl[0].classList.remove("current")
            }
            const currentNavEl = document.getElementById(this.state.data[0].projectName+"Nav")
            currentNavEl.classList.add("current")
            this.redirect = this.redirect.bind(this)
            window.addEventListener('resize', this.checkIfMobile)
            document.addEventListener('keydown', this.handleButtons)
        }

    }

    checkKey(e){
        switch (e.key) {
            case "ArrowLeft":
                console.log('left')
                break;
            case "ArrowRight":
                // Right pressed
                break;
        }
    }

    checkIfMobile = () => {
        const  isMobile  = this.state.isMobile;
        const becameMobile = window.innerWidth < 1025;

        if (isMobile !== becameMobile) {
            this.setState(prevState =>({
                ...prevState,
                isMobile: becameMobile
            }));
        }
    };

    redirect(loc){
        this.setState(prevState=>({
            ...prevState,
            redirect:loc
        }))
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleButtons)
        window.removeEventListener('resize', this.redirect)
        if(this.state.data[0] !== undefined){
            const currentNavEl = document.getElementById(this.state.data[0].projectName+"Nav")
            currentNavEl.classList.remove("current")
        }
    }

    getProject() {
        let projectUrl = window.location.pathname.replace('/work/', '')
        let projects = this.props.project
        let projectData
        let project
        for (project of projects) {
            let projectName = project.post[0][0].posttitle.toLowerCase().replace(/\s/g, '')
            if (projectUrl == projectName) {
                projectData = project
                return [projectData, projectUrl]
            }
        }
    }

    getImage(project, imgNumber){
        if(this.getProject()!=undefined){
            let imageNumber = imgNumber
            let projectData = project[0]
            let projectUrl = project[1]
            let length = projectData.post.map((post, index) => post).reduce((acc, curr) => acc.concat(curr)).length
            let imageData = projectData.post.map((post) => post.filter(this.checkImage, [imageNumber,projectData, projectUrl,length])).reduce((acc, curr) => acc.concat(curr))
            return imageData
        }
    }

    checkImage(i){
        if(this[0] == i.imageid){
            i.text = this[1].text
            i.projectName = this[2]
            i.imageTotal = this[3]
            return i
        }
    }

     //get previous image
     getPrevious(){
        // return last image when the first image is the current image
         return(
             this.state.data[0].imageid > 1 ?
                 this.getImage(this.getProject(),this.state.data[0].imageid-1) :
                 this.getImage(this.getProject(), this.state.data[0].imageTotal)
         )
     }
     // get next image
     getNext(){
         // return first image when last one is the current image
         return(
             this.state.data[0].imageid < this.state.data[0].imageTotal ?
                 this.getImage(this.getProject(),this.state.data[0].imageid +1) :
                 this.getImage(this.getProject(),1)
         )
     }

     // handle image gallery buttons
     handleButtons = (e) => {
         if(e.target.innerHTML === 'previous' || e.key ==='ArrowLeft'){
             const previousImage = this.getPrevious()[0]
             this.props.history.push(this.state.data[0].posttitle.toLowerCase().replace(/\s+/g, '')+ '?image=' +previousImage.imageid)
             this.setState(prevState=>({
                 data:[{
                     ...prevState.data[0],
                     imageid: previousImage.imageid,
                     alt: previousImage.alt,
                     src: previousImage.src,
                     description:previousImage.description
                 }]
             }))
         } else if(e.target.innerHTML === 'next'||e.key ==='ArrowRight'){
             const nextImage = this.getNext()[0]
             this.props.history.push(this.state.data[0].posttitle.toLowerCase().replace(/\s+/g, '')+ '?image=' +nextImage.imageid)
             this.setState(prevState =>({
                 data:[{
                     ...prevState.data[0],
                     imageid: nextImage.imageid,
                     alt: nextImage.alt,
                     src: nextImage.src,
                     description: nextImage.description
                 }]
             }))
         }
     }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.redirect}/>;
        }else if(this.state.isMobile){
            return <Redirect to={'/work'}/>
        } else if(this.state.data[0] === undefined){
            return null;
        }
        //replace \n with <br/ and return parargraphs with line breaks>
        const text = () => {
            let json = this.state.data[0].text.replace(/\n/g, "<br />")
            let text =json.split('<br />')
            const final = text.map( (t,index) => {
                return(
                    <p style={{textAlign:'justify'}}  key={index}>{t}</p>
                )
            })
            return final
        }
        return(
            <div className={"project"}>
                <Helmet>
                    <link
                        rel="canonical"
                        href="noah-mueller.com/work"
                    />
                </Helmet>
                <h2 className="headlineDetail">{this.state.data[0].posttitle}</h2>

                <figure className="detailImg">

                    <Link to={{
                        pathname:'/work',
                        hash:this.state.data[0].posttitle.toLowerCase().replace(/\s+/g, '')
                    }}
                    >
                        <button className="btn" id="backbtn">&#60;&#60; zurück zur Übersicht</button>
                    </Link>

                    <img src={this.state.data[0].src} alt={this.state.data[0].alt} height="410"/>

                    <button id={'prevBtn'} className="galleryNav btn" onClick={this.handleButtons}>previous</button>

                    <span style={{color:"darkgrey"}}>||</span>

                    <button className="galleryNav btn" onClick={this.handleButtons}>next</button>

                    <p className="galleryNav">({this.state.data[0].imageid} von {this.state.data[0].imageTotal})</p>
                    <figcaption className="galleryNav"> {this.state.data[0].description}</figcaption>
                </figure>
                <div className="detailTxt">
                   {text()}
                </div>
            </div>
        );
    }



}
export default WorkDetail;