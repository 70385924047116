import React, {Component} from 'react';
import '../styles/nav.css'
import {Link} from 'react-router-dom'

class Nav extends Component{

    render() {
        const projectList =  this.props.projects.map(project => {
            let hash = project.post[0][0].posttitle.toLowerCase().replace(/\s+/g, '')
            return (
                <li className="work navigation" key={project.id}>
                    <Link onClick={this.props.toggleMenu} key={project.id} to={{
                        pathname: '/work',
                        hash: hash
                    }}>
                        <span className='navSpan' id={hash + 'Nav'}>{project.post[0][0].posttitle}</span>
                    </Link>
                </li>
            )

        })


        return (
            <React.Fragment>
                <nav id={'nav'}>
                    <ul>
                        <li className="navHeadline" id="headlineWork">work</li>
                        {projectList}
                        <li className="navHeadline" id="headlineInfo">info</li>

                        <li className="info navigation" >
                            {/*links zu infopages*/}
                            <Link onClick={this.props.toggleMenu}  to={{
                                pathname: '/info',
                                hash:'about'
                            }}>
                                <span className={'infoNavSpan'} id="aboutNav">About</span>
                            </Link>
                        </li>

                        <li className="info navigation">
                            <Link onClick={this.props.toggleMenu} to={{
                                pathname: '/info',
                                hash:'imprint'
                            }}>
                                <span className={'infoNavSpan'} id="imprintNav">Imprint</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </React.Fragment>
        );
    }
}
export default Nav;
