import React  from "react";
import {Link} from "react-router-dom";
import '../styles/thumbnail.css'

class Thumbnail extends React.Component{
    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        this.setState(
            {longText: this.props.projects.text.length >= 500}
        )
        const hash = window.location.hash
        const projects = document.getElementsByClassName("projects")
        this.navigationHighlighter(hash, projects)

        this.navigationOnscrollHighlighter = this.navigationOnscrollHighlighter.bind(this)
        window.addEventListener("scroll", () => this.navigationOnscrollHighlighter(projects))
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.navigationOnscrollHighlighter)
    }



    navigationHighlighter(hash, projects){
        const prev = document.getElementsByClassName('current')
        if(prev[0]){
            prev[0].classList.remove("current")
        }
        const navEl = document.getElementById(hash.replace('#', '') + 'Nav')
        // if hash value then highlight corresponding nav point
        if(hash && navEl){
            navEl.classList.add('current')
        }
        //if no hash then add current to the first nav point by default
        else{
            document.getElementsByClassName('navSpan')[0].classList.add('current')
            window.history.pushState("","", "/work" + "#" + projects[0].id)
        }

    }


    // find and highlight the current navigation element and change url
    navigationOnscrollHighlighter(projects){
        let project;
        //set offset for navigation highlighter depending on screen size
        let offset;
        if(window.innerWidth<= 1024){
            offset =  document.getElementById("header").getBoundingClientRect().height;
        }else{
            offset = (window.innerWidth*6)/100;
        }
        for (project of projects){
            //get the matching navigation point to the project
            let currentNav = document.getElementById(project.id + "Nav")
            let elementHeight = project.offsetHeight
            // distance to the top of project in the current looping iteration
            let divDistanceTop = project.getBoundingClientRect().top
            if(
                divDistanceTop<= offset+1
                && divDistanceTop>-(elementHeight-offset)
                && !currentNav.classList.contains("current")
            ){
                // remove the class from the previous navigation point
                let prev = document.getElementsByClassName("current")
                if(prev[0]){
                    prev[0].classList.remove("current")
                }
                //add class to new nav point
                currentNav.classList.add("current")
                //change url depending on site scroll
                window.history.pushState("","", "/work" + "#" +project.id)
            }
        }
    }

    toggleText(e){
        let parent = e.target.parentElement
        let moreText = parent.getElementsByClassName('moreText')[0]
        let dots = parent.getElementsByClassName('dots')[0]
        let targetBtn = e.target
        if(targetBtn.innerHTML == 'read more'){
            targetBtn.innerHTML = 'less'
            moreText.style.display = 'inline'
            dots.style.display = 'none'
        }else{
            let moreTextHeight = moreText.getBoundingClientRect().height
            window.scrollBy(0,-moreTextHeight+22)
            targetBtn.innerHTML = 'read more'
            moreText.style.display = 'none'
            dots.style.display = 'inline'
        }
    }

    //add a span to where the later a html element will be added
     insertAt = (index, element) =>{
         let el = element
         let projectTxt = this.props.projects.text;
        if(projectTxt.length < 500){
            return projectTxt
        }
        for(let i = index; i<=projectTxt.length;i++){
            if(projectTxt[i] == ' '){
                return projectTxt.slice(0, i) + el +projectTxt.slice(i)
            }
        }
    }


    render(){
        const imageList = this.props.info.map(pics => {
            return(
                pics.map(pic =>{
                    return (
                        <React.Fragment key={pic.imageid}>
                            <Link to={{
                                pathname: "../work/" + pic.posttitle.toLowerCase().replace(/\s+/g, '') + '?image='+ pic.imageid,
                            }}>
                                <figure className='thumbnail'>
                                    <img src={pic.src} alt={pic.alt} height="150"/>
                                    <figcaption className="thumbnailNumber">{pic.caption}</figcaption>
                                </figure>
                            </Link>
                        </React.Fragment>
                    )
                })
            )
        })
        const galleries = imageList.map( (gallery, index) => {
            return(
                <div key={index}>
                    {gallery}
                </div>
            )
        })

        //process text so that it has line breaks and works with load more button
        const text = () => {
            let inserted = this.insertAt(250, '<span />')
            //replace \n with <br/ and return parargraphs with line breaks>
            let json = inserted.replace(/\n/g, "<br />")
            let text = json.split('<span />')
            const brs = text.map(t => t.split('<br />'))
            const paragraphs  = brs.map( (textPart,index) =>
                {
                    if(this.state.longText == false){
                        return textPart.map((p, index) => <p key={index}>{p}</p>)
                    }
                    else if(index == 0){
                        return textPart.map((p, index) =>index == textPart.length-1
                            ? <p key={index} style={{display:'inline'}} key={index}>{p}<span className="dots">...</span></p>
                            : <React.Fragment key={index}>{p}</React.Fragment>)
                    }
                    else{
                        return textPart.map((p, index) => {
                            return index != 0 ? <p key={index}>{p}</p> : <p style={{display:'inline'}} key={index}>{p}</p>
                        })
                    }
                })
            let test = <React.Fragment>{paragraphs[0]}<span  className="moreText">{paragraphs[1]}</span></React.Fragment>
            return test
        }

        return(
            <div className={`projects ${this.props.last}`} id={
                this.props.info[0][0].posttitle.toLowerCase().replace(/\s+/g, '')
            }>
                <h2 className="headline">{this.props.info[0][0].posttitle}</h2>
                {galleries}
                <div className="projectTxt">
                    {text()}
                    <button style={this.state.longText == false ? {display: 'none'} : null} onClick={this.toggleText} className={'toggleText'}>read more</button>
                </div>
            </div>
        )
    }
}

export default Thumbnail

